@font-face {
  font-family: 'Roboto';
  src: //url('../fonts/AvenirNextCyr-Medium.woff') format('woff'),
  url('../fonts/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: //url('../fonts/AvenirNextCyr-MediumItalic.woff') format('woff'),
  url('../fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: //url('../fonts/AvenirNextCyr-Demi.woff') format('woff'),
  url('../fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: //url('../fonts/AvenirNextCyr-DemiItalic.woff') format('woff'),
  url('../fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: //url('../fonts/AvenirNextCyr-Medium.woff') format('woff'),
url('../fonts/Raleway-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Raleway';
  src: //url('../fonts/AvenirNextCyr-MediumItalic.woff') format('woff'),
url('../fonts/Raleway-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Raleway';
  src: //url('../fonts/AvenirNextCyr-Demi.woff') format('woff'),
url('../fonts/Raleway-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Raleway';
  src: //url('../fonts/AvenirNextCyr-DemiItalic.woff') format('woff'),
url('../fonts/Raleway-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Raleway';
  src: //url('../fonts/AvenirNextCyr-DemiItalic.woff') format('woff'),
url('../fonts/Raleway-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

//@import "_colors";
@import '../scss_critical/critical';
//@import "_normalize";

//@import "_bootstrap-grid";
//@import "_bootstrap_3";
//@import "_jquery-ui";
@import "_jquery-fancybox";
@import "_slick";
//@import "_jquery.mCustomScrollbar";
//@import "_animate";
//@import "_header";
//@import "_search";
//@import "_menu-left";
@import "_triggers";
@import "_slider";
@import "_home";
@import "_cards";
@import "_form";
@import "_content";
@import "_404";
//@import "_breadcrumbs";
//@import "_catalog";
//@import "_content-page";
@import "_footer";



/* TEMP */
//.container{
//  outline:1px solid black;
//}
//body{
//  background: green;
//}
/* / TEMP */

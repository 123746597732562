.header{
  width:100%;
}
.wrapper{
  .nav-wrapper{
    border-bottom: 1px solid $gray-extra-light;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    transition: .3s;
    position: relative;
    padding: 15px;
  }
  .nav-wrapper__close{
    display: none;
    width: 30px;
    height: 30px;
    top:10px;
    right: 10px;
    position: absolute;
  }
  .nav{
    display: flex;
    align-items: center;

    a{
      color: $main-color;
      font-size: 16px;
      margin-right: 22px;
      &:hover{
        text-decoration: underline;
      }
    }

  }
  .high-lighted{
    a{
      color: $orange;
      font-weight: 600;
    }
  }
  .login{
    display: flex;
    align-items: center;
    a{
      color:$main-color;
      font-weight: 500;
      font-size: 16px;
      margin-left: 22px;
    }
  }
  .location{
    color:$main-color;
    font-weight: 500;
    font-size: 16px;
    margin-left: 22px;
  }
  .nav-wrapper-bot{
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-between;
    align-items: flex-end;
  }
}
.logo{
  margin-right: 20px;
  margin-top: -3px;
  &-block{
    display: flex;
    align-items: flex-end;
  }
  &-block__info{

  }
  &-block__name{
    color:$orange;
    font-size: 28px;
    font-weight: 600;
    margin-bottom:11px;
    span{
      color:$gray;
    }
  }
  &-block__address{
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
  }
  &-block__time{
    font-size: 20px;
    font-weight: 500;
  }

}
.phone-block{
  color: $gray;
  font-size: 20px;
  &__number{
    color: $main-color;
    font-size: 28px;
    line-height: 28px;
    font-weight: 600;
    display: block;
  }
}
.header-cart{
  text-align: right;
  &__title{
    color: $gray;
    font-size: 20px;
    font-weight: 600;
  }
  &__price{
    color: $orange;
    font-size: 28px;
    font-weight: 600;
    display: flex;
    align-items:flex-end;
    line-height: 28px;
    justify-content: flex-end;
  }
  &__amount{
    width:22px;
    height:22px;
    position: absolute;
    border-radius:50%;
    color: $white;
    background-color: $orange;
    border:1px solid $white;
    top: -5px;
    right: -16px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__icon{
    width: 28px;
    height: 28px;
    position: relative;
    background-image: url(../images/shopping-cart.svg);
    //margin-bottom: 4px;
    -webkit-background-size: cover;
    background-size: cover;
    top: -3px;
  }
  &__empty{
    font-size: 14px;
    line-height: 18px;
    margin-left: 10px;
    font-weight: 400;
    display: block;
    margin-bottom: 4px;
  }
  &__total-cost{
    margin-left: 20px;
  }
  &:hover{

  }
}

.catalog-line{
  background-color: $orange;
  color: $white;
  height: 56px;
  width:100%;
  z-index:40;
  .header-cart__inner{
    color: $main-color;
  }
}
#undefined-sticky-wrapper{
  width:100%;
}
header{
  background-color: $white;
}
.menu__block{
  display: flex;
  align-items: center;
  //justify-content: space-between;
  &-inner{
    display: flex;
    align-items: center;
    color: $white;
    background: none;
    outline: none;
    border: none;
    width:100%;
  }
}
.menu__icon{
  width: 30px;
  height: 22px;
  //margin-right: 15px;
  background-image: url(../images/menu.svg);
  cursor: pointer;
}
.menu__title{
  font-size: 22px;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}
.location{
  position: relative;
  display: flex;
  align-items: center;
}
.location:hover{
  text-decoration: underline;
}
.login__link{
  display: flex;
  align-items: center;
}
.login__link:hover{
  text-decoration: underline;
}
.location:before{
   content: "";
   display: block;
   width: 14px;
   height: 15px;
   margin-right: 6px;
   background-image: url(../images/marker.svg);
}
.login__link:before{
   content: "";
   display: block;
   width: 15px;
   height: 15px;
   margin-right: 6px;
   background-image: url(../images/user.svg);
}
.header__block-title{
  margin-bottom: 6px;
  display: inline-block;
}
.logo-mobile{

  color: $orange;
  img {
    width: 32px;
    display: none;
  }

}
.mobile-menu-opener{
  width: 22px;
  display: none;
  position: relative;
  z-index:2;
  div{
    width: 100%;
    height: 3px;
    margin-bottom: 4px;
    cursor: pointer;
    background-color: $orange;
  }
}
.logo-mobile-only{
  display: none;
}
.header-cart{
  cursor: pointer;
  position: relative;
  &__inner{
    top:calc(100%);
    position: absolute;
    right:-2px;
    z-index:10;
    padding-top: 20px;
  }
  &__items{
    display: none;
    text-align: left;
    background-color: $white;

    max-width: 396px;
    width: 100vw;
    padding: 20px 20px 30px 20px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    &.visible{
      display: block;
    }
  }
  &__items-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 25px;
    padding-bottom: 25px;
    border-bottom:1px solid $gray-extra-light;
    text-align: left;
  }
  &__items-title{
    font-size: 22px;
    text-align: left;
  }
  &__items-bot{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  &__items-link{
    color: $orange;
    text-decoration: underline;
    font-size: 14px;
    &:hover{
      text-decoration: none;
    }
  }
  &__items-bot-right{
    display: flex;
    align-items: center;
  }
  &__items-total{
    font-size: 22px;
    font-weight: 500;
    color: $main-color;
  }
  &__items-offer{
    display: block;
    color: $white;
    font-size: 14px;
    font-weight: 500;
    width: 112px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: $orange;
    border-radius: 4px;
    margin-left: 24px;
    &:hover{
      opacity: .85
    }
  }
  &__items-img{
    width: 46px;
    height: 50px;
    margin-right: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      max-width:100%;
      max-height:100%;
    }
  }
  &__items-product{
    font-size: 14px;
    color: $orange;
    margin-bottom: 6px;
    display: block;
    &:hover{
      text-decoration: underline;
    }
  }
  &__items-spec{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__items-info{
    width: 100%;
  }
  &__items-price{
    flex:1 1 33.3333%;
    font-size: 14px;
  }
  &__items-amount{
    flex:1 1 33.3333%;
    text-align: center;
    font-size: 14px;
  }
  &__items-cost{
    flex:1 1 33.3333%;
    text-align: right;
    font-size: 22px;
    font-weight: 500;
  }
  &:hover{
    .header-cart__items{
      display: block;
    }
  }
  &.line-in{
    height: 56px;
    display: flex;
    align-items: center;
    .header-cart__title{
      display: none;
    }
    .header-cart__price{
      color: $white;
    }
    .header-cart__icon{
      margin-bottom: 0;
      background-image: url(../images/shopping-cart-light.svg);
    }
    .header-cart__inner{
      padding-top: 0;
    }
  }
}
.form-wr{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mobile-only-phones{
  display: none;
}
.mobile-only-item{
  display: none;
}
.mobile-only-icon-wr{
  display: none;
}
.sticky-catalog-line{
  .header-cart__amount{
    background-color: $blue-bright;
    border: none;
  }
  .header-cart__mob-title{
    font-size: 22px;
    font-weight: 400;
    margin-right: 6px;
    display: block;
  }
  .catalog-line{
    z-index: 110;
  }
}
.header-cart__mob-title{
  display: none;
}
@media(max-width: 1365px){
  .wrapper{
    .nav{
      a{
        font-size: 15px;
        margin-right: 14px;
      }
    }
    .login{
      a{
        font-size: 15px;
        margin-left: 14px;
      }
    }
  }
  .phone-block, .header-cart__title{
    font-size: 16px;
  }
  .phone-block__number{
    font-size: 20px;
    line-height:1;
  }
  .header-cart__price{
    font-size: 20px;
    line-height: 1;
  }
  .header-cart__amount{
    top:0;
  }
  .header__block-title{
    margin-bottom: 0;
  }
  .header-cart__icon{
    margin-right: 4px;
    margin-bottom: 0;
  }
  .header-cart__amount{
    right: -20px;
  }
  .logo-block__time{
    font-size: 16px;
  }
  .logo{
    width: 110px;
  }
}
@media(max-width: 1199px){
  .logo-mobile-only{
    display: block;
    a{
      display: flex;
      align-items: center;
    }
    img{
      width: 82px;
    }
    .logo-mobile-only__name{
      margin-left: 8px;
      font-size: 18px;
      color: $orange;
      font-weight: 600;
      span{
        color: $gray;
      }
    }
  }
  .mobile-only-item{
    display: block;

  }
  .mobile-only-icon-wr{
    position: absolute;
    margin-right: 6px;
    width: 24px;
    height:24px;
    left:0;
    top:50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      max-width:100%;
      max-height:100%;
    }
  }
  .mobile-only-phones{
    padding-top: 10px;
    margin-top: 10px;
    border-top: 1px solid $gray-extra-light;
    order: 30;
    display: block;
    width:100%;
    .phone-block{
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .phone-block__number{
      font-size: 16px;
    }
    .header__block-title{
      font-size: 16px;
      display: block;
    }
    .work-time{
      opacity:.4;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .header{
    background-color: $soft-milky;
  }
  .phone-block{
    display: none;
  }
  .catalog-line{
    background-color: $soft-milky;
    height: 56px;
    .menu__block{
      display: none;
    }
    &.box-shadow{
      box-shadow: none;
    }
  }
  .wrapper{
    .nav-wrapper{
      position: fixed;
      transform: translateX(-120%);
      top:0;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 320px;
      padding-bottom: 30px;
      box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 2px 2px rgba(0,0,0,.06), 0 0 2px rgba(0,0,0,.07);
      .nav-wrapper__close{
        display: block;
        &:before{
          content: "";
          display: block;
          width: 30px;
          height: 2px;
          position: absolute;
          top:12px;
          left:0px;
          background: $gray-light;
          transform: rotate(45deg);
        }
        &:after{
          content: "";
          display: block;
          width: 30px;
          height: 2px;
          position: absolute;
          top:12px;
          left:0px;
          background: $gray-light;
          transform: rotate(-45deg);
        }
      }
      .nav{
        order:20;
        flex-direction: column;
        align-items: flex-start;
        a{
          margin-left: 0;
          padding-left: 40px;
          position: relative;
        }
      }
      .login{
        flex-direction: column;
        align-items: flex-start;
        order:10;
        margin-bottom: 10px;
        padding-bottom: 6px;
        border-bottom: 1px solid $gray-extra-light;
        width:100%;
        a{
          margin-left: 0;
        }
      }
      .location{
        margin-left: 0;
        order:40;
      }
      &.visible{
        background-color:$white;
        max-height:100vh;
        overflow-y: auto;
        z-index:107;
        transform: translateX(0%)
      }
      a{
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
      }
    }
    .nav-wrapper-bot{
      padding-top: 10px;
      padding-bottom: 10px;
      align-items: center;
      height: 63px;
      position: relative;
    }
  }
  .logo-block{
    position: absolute;
    left:50%;
    transform: translateX(-50%);
    width:100%;
    display: flex;
    justify-content: center;
  }
  .header__block-title{
    display: none;
  }
  .header{
    .logo{
      display: none;
    }
  }
  .logo-block__name{
    font-size: 18px;
    margin-bottom: 6px;
    text-align: center;
  }
  .logo-block__address{
    font-size: 14px;
    text-align: center;
  }
  .logo-block__time{
    font-size: 14px;
  }
  .logo-mobile{
    display: inline-block;
    margin-right: 4px;
  }
  .header-cart__total-cost{
    font-size: 18px;
  }
  .mobile-menu-opener{
    display: block;
  }
  .sticky-catalog-line{
    .catalog-line{
      &.box-shadow{
        box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 2px 2px rgba(0,0,0,.06), 0 0 2px rgba(0,0,0,.07);
      }
      .header-cart__total-cost{
        color: $orange;
      }
      .header-cart__icon{
        background-image: url(../images/shopping-cart.svg);
      }
    }
  }
}
@media(max-width: 767px){
  .logo-block__address{
    display: none;
  }
  .header-cart__total-cost{
    font-size: 12px;
  }
  .logo-block__name{
    margin-bottom: 0;
  }
  .sticky-catalog-line{
    .header-cart__mob-title{
      font-size: 14px;
      margin-right: 2px;
    }

  }
}
@media(max-width: 480px){
  .logo-block__name{
    display: flex;
    span{
      margin-left: 4px;
      display: inline-block;
    }
  }
  .logo-mobile{
    width:auto;
    display: flex;
    color: $orange;
    img{
      display: none;
    }

  }
}
@media(max-width: 390px){
  .logo-block__name{
    font-size: 14px;
  }
  .sticky-catalog-line{
    .header-cart__mob-title{
      display: none;
    }
  }

}

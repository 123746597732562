.footer{
  background-color: $milky;
  padding-top: 40px;
  padding-bottom: 40px;
  ul{
    li{
      padding:8px 0;
      a{
        font-size: 14px;
        line-height:1.2;
        color: $main-color;
        &:hover{
          text-decoration: underline;
        }
      }
    }
  }
  &__phones{
    display: flex;

    .phone-block{
      font-size: 14px;
      margin-right: 30px;
      &__number{
        font-size: 18px;
      }
    }
  }
  &__logo{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .logo{
      margin-right: 82px;
      width: 106px;
    }
    .logo-block__name{
      font-size: 14px;
      display: flex;
      margin-bottom: 0;
      span{
        margin-left: 4px;
      }
    }
  }
}
.copyright{
  margin-top: 20px;
}
.leann-link{
  margin-top: 20px;
  color: $gray-light;
}
.metrics{
  &.temp{
    min-width: 154px;
    min-height:56px;
    border:1px solid  $main-color;
    border-radius:6px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #fff;
    div{
      font-size: 9px;
      background: #ccc;
      color: $main-color;
      padding: 4px 10px;
      width: 100%;
    }
  }
}
@media(max-width: 1365px){
  .footer__phones{
    flex-direction: column;
    .phone-block__number{
      margin-top: 4px;
    }
  }
  .footer{
    .phone-block{
      margin-bottom: 10px;
    }
  }

}
@media(max-width: 1199px){
  .wrapper{
    .footer{
      ul{
        margin-bottom: 20px;
      }
      .col-xl-1{
        display: none;
      }
    }
    .col-info{
      flex: 0 0 100%;
      max-width: 100%;
    }
    .col-ul-1,
    .col-ul-2{
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}
@media(max-width: 767px){
  .wrapper{
    .col-ul-1,
    .col-ul-2{
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
.triggers{
  display: flex;
  width: 100%;
}
.trigger{
  height: 175px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  &:after{
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    z-index:2;
    position: absolute;
    background-color: $brown;
    opacity:.7;
  }
  img{
    width:100%;
    height:100%;
    object-fit: cover ;
  }
  &__text{
    text-shadow: 0px 0px 13px $text-gray;
    position: absolute;
    z-index:3;
    top:52%;
    left: 30px;
    transform: translateY(-50%);
    color: $white;
    font-weight: 500;
    font-size: 18px;
    line-height:1.4;
    max-width: calc(100% - 60px);
    &:after{
       content: "";
       display: block;
       width: 100%;
       height: 1px;
       background-color: $white;
      position: absolute;
      left:0;
      bottom:-14px;
    }
  }
}
@media(max-width: 1365px){
  .trigger{
    height: 230px;
  }
}
@media(max-width: 1199px){
  .trigger{
    height: 98px;
  }
  .trigger__text{
    top: 47%;
    &:after{
      bottom: -10px;
    }
  }
}
@media(max-width: 991px){
  .trigger__text{
    max-width: calc(100% - 30px);
    left:15px;
    font-size: 16px;
  }
}
@media(max-width: 767px){
  .wrapper{
    .triggers{
      width: 100%;
      height: 250px;
      display: block;
    }
    .trigger__text{
      text-align: center;
      top:50%;
      width:100%;
      &:after{
        display: none;


      }
    }
  }
}

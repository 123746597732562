.form{
  &-wrapper{
    &__inner{
      position: relative;
    }
  }
  &__reg{
    background-color: $white;
    max-width: 1080px;
    width: 100vw;
    max-height: 100vh;
    overflow-y: auto;
    &__inner{
      box-shadow: 0 -10px 60px -25px lighten($gray, 15%);
      padding: 60px;
      width: 1080px;
      height: 100%;
      max-width: 100vw;
      max-height: 100vh;
      overflow-y: auto;
    }
    .form-wrapper__inner{
      padding: 60px;
    }
  }
  &__login{
     background-color: $white;
     width: 100vw;
     max-width: 780px;
     overflow-y: auto;
    .form-wrapper__inner{
      padding-top:60px;
      padding-bottom:60px;
    }
    .form{
      max-width: 512px;
      margin: 0 auto;
    }
    .form__submit{
      max-width:100%;
    }
  }
  input{
    border:1px solid $green;
    width:100%;
    height: 50px;
    padding-left: 15px;
    padding-right: 15px;
    background: none;
    &[type=submit]{
      width:100%  ;
      display: block;
      font-size: 24px;
      font-weight: 700;
      color: $white;
      position: relative;
      z-index:2;
    }
  }
  &__submit{
    max-width: 450px;
    margin: 0 auto 30px;
    background-color: $green;
    position: relative;
    &:after{
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      transition: .3s;
      width: 0;
      height: 100%;
      background-color: $gray;
    }
    &:hover{
      &:after{
        width: 100%;
      }
    }
  }
  &__close{
    cursor: pointer;
    position: absolute;
    top: 50px;
    right: 50px;
    z-index:2;
    width: 20px;
    height: 20px;
    transition: .3s;
    &:before{
      content: "";
      display: block;
      width: 20px;
      height: 2px;
      position: absolute;
      left:0;
      top:9px;
      background-color: $green;
      transform: rotate(45deg);
    }
    &:after{
      content: "";
      display: block;
      width: 20px;
      height: 2px;
      position: absolute;
      left:0;
      top:9px;
      background-color: $green;
      transform: rotate(-45deg);
    }
    &:hover{
      transform: rotate(90deg);
    }
  }
  &__title{
    font-size: 40px;
    color: $green;
    text-transform: uppercase;
    margin: 0 auto 14px;
    text-align: center;
    font-weight: 700;

  }
  &__subtitle{
    font-size: 24px;
    color: $black;
    font-weight: 700;
    text-align: center;
    margin: 0 auto 40px;
  }
  &__field{
    margin-bottom: 30px;
    &-mg{
      height: 50px;
    }
  }
  &__notice{
    margin-top: 6px;
  }
  &__error{
    color: $red;
  }
  &__success{
    color: $green;
  }
  &__have-acc{
    text-align: center;
    margin-top: 30px;
    color: $gray;
    a{
      color: $gray;
      transition: .3s;
      font-weight: 600;
      text-decoration: underline;
      &:hover{
        color: $black;
      }
    }
  }
  &__thanks{
    background-color: #fff;
    width: 100vw;
    max-width: 780px;
    max-height: 100vh;
    overflow-y: auto;
    .form-wrapper__inner{
      padding: 100px 60px;
    }
    &-link{
      font-size: 24px;
      font-weight: 700;
      color: $white;
      max-width:448px;
      background-color:$green ;
    }
  }
  &__login-links{
    display: flex;
    align-items: center;
    justify-content: space-between;
    a{
      color: $gray;
      text-decoration: underline;
      transition: .3s;
      &:hover{
        color: $black;
      }
    }
  }
  &__notice{
    display: none;
    &.visible{
      display: block;
    }
  }
  &__social{
    margin-top: 40px;
    ul{
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex-wrap: wrap;
      li{
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 15px;
        a{
          background-repeat:no-repeat;
          background-position: center center;
          background-size: 100%;
          display: block;
          border-radius: 50%;
          overflow: hidden;
          width: 46px;
          height: 46px;
        }
      }
    }
  }
  &__google{
    background-image: url(../images/google.svg);
  }
  &__vk{
    background-image: url(../images/vk_.svg);
  }
  &__facebook{
    background-image: url(../images/fb.svg);
  }
  &__odnoklassniki{
    background-image: url(../images/ok.svg);
  }
  &__thanks-link{
    font-size: 24px;
    font-weight: 700;
    color: $white;
    display: block;
    background-color: transparent;
    text-align: center;
    transition: .3s;
    position: relative;
    z-index:2;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 70px;
    &:hover{
      color: $white;
    }
  }
  &__notice-small{
    margin-top: 0;
    a{
      font-size: 12px;
      color: $black;
      text-decoration: underline;
    }
  }
}

.popup{
  opacity: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index:9001;
  transition: .5s;
  width:0;
  max-height: 100vh;
  overflow: hidden;
  &.visible{
    width: 100vw;
    opacity: 1;
  }
}
@media(max-width: 480px){
  .form{
    &__title{
      font-size: 22px;
    }
    &__subtitle{
      font-size: 18px;
    }
    &__reg{
      .form-wrapper__inner{
        padding: 20px;
      }
    }
    &__close{
      top: 20px;
      right: 20px;
    }
  }
}
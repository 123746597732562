.slider-main{
  height: 361px;
  margin-top: 30px;
  margin-bottom: 42px;
  border-radius: 8px;
  transition: .3s;
  opacity: 0;
  .slick-slide{
    border-radius: 8px;
    overflow: hidden;
    height: 361px;
    position: relative;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &:before{
      content: "";
      display: block;
      width: 50%;
      height: 100%;
      background: $brown;
      z-index: 2;
      opacity:.8;
      left:0;
      top:0;
      position: absolute;
    }
  }
  .slick-dots{
    position: absolute;
    bottom:-26px;
    display: flex;
    justify-content: center;
    left:50%;
    transform: translateX(-50%);
    li{
      margin-left: 14px;
      margin-right: 12px;
      button{
        width: 12px;
        height: 12px;
        background-color: $white;
        border:1px solid $brown;
        font-size: 0;
      }
      &.slick-active{
        button{
          background-color: $brown;
        }
      }
      &:hover{
        button{
          background-color: $brown;
        }
      }
    }
  }
  &.visible{
    opacity:1;
  }
  &__info{
    position: absolute;
    z-index:2;
    top:50%;
    transform: translateY(-50%);
    width:50%;
    color: $white;
    font-size: 48px;
    line-height: 1.3;
    padding-left: 30px;
    padding-right: 30px;
    height: 100%;
    display: flex;
    align-items: center;
    text-shadow: 0px 0px 13px $text-gray;
    &:before{
      content: "";
      display: block;
      width: calc(100% - 60px);
      height: 1px ;
      background-color: $white;
      position: absolute;
      top: 30px;
    }

  }
  &__more{
    position: absolute;
    bottom: 30px;
    z-index:3;
    font-size: 14px;
    width:calc(100% - 60px);
    display: flex;
    align-items: center;
  }
  &__more-line{
    height: 1px;
    background-color: $white;

  }
  &__more-line-before{
    margin-right: 25px;
    width:68%;
  }
  &__more-line-after{
    margin-left: 25px;
    width:6%;
  }
}
@media(max-width: 1365px){
  .slider-main__info{
    font-size: 40px;
  }
}

@media(max-width: 1199px){
  .slider-main{
    height: 250px;
    .slick-slide{
      height: 250px;
    }
  }
}
@media(max-width: 991px){
  .slider-main__info{
    font-size: 30px;
  }
}
@media(max-width: 767px){
  .slider-main__info{
    font-size: 20px;
  }
  .slider-main{
    display: none;
  }
  .wrapper{
    .triggers{
      margin: 30px 0;
      height: 100px;
      .slick-dots{
        position: absolute;
        bottom:-26px;
        display: flex;
        justify-content: center;
        left:50%;
        transform: translateX(-50%);
        li{
          margin-left: 14px;
          margin-right: 12px;
          button{
            width: 12px;
            height: 12px;
            background-color: $white;
            border:1px solid $brown;
            font-size: 0;
          }
          &.slick-active{
            button{
              background-color: $brown;
            }
          }
          &:hover{
            button{
              background-color: $brown;
            }
          }
        }
      }
    }
  }

}


$main-color: #555555;

// common
$black: #444444;
$black-soft: #212121;
$milky: #F2F2F2;
$soft-milky: #fafafa;
$white: #fff;

// gray
$gray-dark: #3d3d3d;
$gray-soft: #7d7978;
$gray: #7D7A7A;
$gray-light: #a9a9a9;
$gray-extra-light: #EFEFEF;
$text-gray: #797979;


// green
$green: #4c9603;

// red
$red: #FF4848;

// orange
//$orange: #F28241;
$orange: #f9730e; // bright
$orange-light: #F8A370;
$brown: #B57752;

// blue
$blue-bright: #025BFA;
$blue-light: #99bdc8;
$blue: #7fadba;
$blue-darker: #5f828b;
$blue-dark: #596674;





.tabs{
  display: flex;
  overflow-x: auto;
  margin-top: 40px;
  margin-bottom: 10px;
}
.tab{
  color: $orange;
  border: 1px solid $orange;
  text-align: center;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  margin-right: 20px;
  padding-right: 15px;
  padding-left: 15px;
  height: 32px;
  font-size: 16px;
  line-height: 32px;
  margin-bottom: 20px;
  cursor: pointer;
  background-color: $white;
  display: flex;
  white-space:nowrap;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
  &:hover{
    background-color: $orange;
    color: $white;
  }
  &.active{
    background-color: $orange;
    color: $white;
  }
}
@media(max-width: 767px){
  .tabs{
    margin-top: 10px;

  }
}

.menu__block-home{
  .left-menu{
    top:0;
  }
}
.left-menu{
  position: absolute;
  padding:10px 0 10px 15px;
  background-color: $white;
  top:100%;
  left:15px;
  z-index:101;
  border-radius: 0 0 8px 8px;
  width:calc(100% - 30px);
  display: none;
  &.visible{
    display: block;
  }
  ul{
    padding-left: 0;

    li{
      padding-right: 15px;
      width:100%;
      a, span{
        color: $main-color;
        position: relative;
        padding: 6px 12px 6px 30px;
        display: block;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none;   /* Chrome/Safari/Opera */
        -moz-user-select: none;      /* Firefox */
        -ms-user-select: none;       /* Internet Explorer/Edge */
        user-select: none;
        &:before{
          content: "";
          display: block;
          width: 6px;
          height: 10px;
          background-image: url(../images/chevron.svg);
          position: absolute;
          top:10px;
          right:2px;
        }
        div{
          width: 18px;
          height: 18px;
          position: absolute;
          left: 0;
          top: 6px;
          display: flex;
          justify-content: center;
        }
        &:hover{
          color: $orange;
        }
      }
      img{
        max-width:100%;
        max-height:100%;
      }
      &:hover{
        .sub-menu{
          display: block;
        }
      }
    }
  }
}
.sub-menu{
  position: absolute;
  top:0;
  display: none;
  background-color: $white;
  z-index:2;
  width:100vw;
  max-width:1025px;
  min-height:608px;
  left: 100%;
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-gap: 20px;
  -moz-column-gap: 20px;
  column-gap: 20px;
  padding: 20px;
  li{
    a{
      &:before{
        display: none!important;
      }
    }
  }
}


@media(max-width: 1365px){
  .sub-menu{
    max-width:855px;
  }
}
@media(max-width: 1199px){
  .left-menu{
    display: none;
    &.visible{
      display: none;
    }
  }
}
::-webkit-input-placeholder {
  color: $white;
}
::-moz-placeholder  {
  color: $white;
}
:-moz-placeholder{
  color: $white;
}
::selection {

}
::-moz-selection {

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='number'] {
  -moz-appearance: textfield;
}
ul{
  li{
    list-style-type:none;
  }
}

//ol{
//  counter-reset: item;
//  li{
//    &:before {
//      content: counter(item) " ";
//      counter-increment: item;
//    }
//  }
//}
ol{
  padding-left: 20px;
}

html, body{
  min-height:100%;
}
body{
  min-width:360px;
  font-weight:normal;
  font-style: normal;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height:1.3;
  color: $main-color;
  background-color: $soft-milky;
}
.wrapper{
  width: 100%;
}
.table-wrapper{
  max-width: 100%;
}
@media(max-width:1199px){
  .table-wrapper{
    overflow: auto;
  }
}
.table{
  display: table;
  width: 100%;
}
.tr{
  display: table-row;
}
.td{
  display: table-cell;
}
.display-none{
  display: none!important;
}
h1, .h1like{
  font-size: 30px;
  margin-bottom: 20px;
}
h2, .h2like{
  font-size: 24px;
  margin-bottom: 20px;
}
h3, .h3like{
  font-size: 18px;
  margin-bottom: 10px;
}
h4, .h4like{

}
h5, .h5like{

}
.box-shadow{
  box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 2px 2px rgba(0,0,0,.06), 0 0 2px rgba(0,0,0,.07)
}

.no-border{
  border: none!important;
}
.pb-25{
  padding-bottom: 25px;
}
.container-spec {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}
a,
a:focus,
a:visited{
  text-decoration: none;
}

p{
  margin: 20px 0;
}
.small{
  font-size: 11px;
  line-height: 12px;

}
img{
  display: block;
  max-width:100%;
}
td{
  img{
    max-width:none;
  }
}
.f-l{
  float: left;
}
.f-r{
  float: right;
}
.clear{
  clear: both;
  position: relative;
}
.text-align-center{
  text-align: center;
}
.btn{

  &:before{

  }
  &:hover{
    &:before{

    }
  }
}
.overflow-hidden{
  overflow: hidden;
}
.h100vh{
  height: 100vh;
}
input,
option,
button,
textarea{
  &:hover{
    outline:none;
  }
  &:focus{
    outline:none;
  }
  &:active{
    outline:none;
  }
}
img,
a,
button,
img:active,
a:active,
button:active,
img:focus,
a:focus,
button:focus,
img:visited,
a:visited,
button:visited{
  border:none;
  outline:none;
}

input[type="text"],
input[type="number"],
input[type="password"],
input[type="email"],
label.upload-file,
textarea{

}

.file_load{
  border-radius: 4px;
  border:2px solid $white;
  &:hover{
    border:2px solid $white;
  }
}
.main_input_file {
  display: none;
}
.upload_form{
  margin-top: 20px;
}
.upload_form div {
  height: 32px;
  border-radius: 4px;
  color: #fff;
  text-align: center;
  line-height: 32px;
  font-family: Roboto, arial, sans-serif;
  font-size:14px;
  display: inline-block;
  vertical-align: top;
}

.upload_form div:hover {
  cursor: pointer;
}

.f_name {
  background: transparent;
  border: 0;
  display: inline-block;
  vertical-align: top;
  height: 30px;
  padding: 0 8px;
  text-align: center;
}

textarea{
  resize:vertical;
  padding: 15px;
  width:100%;
  border-radius: .25rem;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
}
.hidden-block{
  display: none;
  width: 100%;
  .row{
    margin-left: 0;
    margin-right: 0;
  }
}
a{
  text-decoration: none;
}
select{
  width: 100%;
  border: none;
  outline:none;
  font-family: 'Roboto',Arial,sans-serif;
  option{
    width: 100%;
    border: none;
    outline:none;
    max-width:100%;
    font-family: 'Roboto',Arial,sans-serif;
    padding-left: 15px;
    padding-right: 15px;
    &:active, &:focus, &:visited{
      border: none;
      outline:none;
    }
  }
  &:active, &:focus, &:visited{
    border: none;
    outline:none;
  }
}
.temp-text{
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  max-width: 954px;
  margin: 0 auto;
  padding: 0 15px;
  color: $white;
  text-align: center;
  text-transform: uppercase;
}
section{
  position: relative;
  &.content{
    padding-top: 60px;
  }
  .orange{
    color: $orange;
  }
  .green{
    color: $green;
  }
  .red{
    color: $red;
  }
  .white{
    color: $white;
  }
  .blue{
    color: $blue;
  }
  .black{
    color: $black;
  }
}
input[type="checkbox"]{
  display: none;
}
.checkbox{
  padding-left: 38px;
  position: relative;
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  vertical-align: middle;
  .checkmark{
    position: absolute;
    display: block;
    width: 20px;
    height: 20px;
    top: 0px;
    left: 4px;
    border:2px solid $black;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    &:before{
      content: "";
      display: none;
      width: 12px;
      height: 7px;
      position: absolute;
      top: 4px;
      left: 3px;
      border-left:2px solid $black;
      border-bottom:2px solid $black;
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }
  input[type="checkbox"]:checked + .checkmark:before{
    display: block;
  }
}

input[type="radio"]{
  display: none;
}
.radio{
  padding-left: 30px;
  position: relative;
  display: inline-block;
  cursor: pointer;
  .checkmark{
    position: absolute;
    display: block;
    width: 16px;
    height: 16px;
    top: 4px;
    left: 4px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    &:before{
      content: "";
      display: none;
      width: 8px;
      height: 8px;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;

    }
  }
  input[type="radio"]:checked + .checkmark:before{
    display: block;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
input[type="submit"],
input[type="submit"]:active,
input[type="submit"]:focus{
  outline: none;
  border: none;
}
hr{
  height:1px;
  border:none;
  outline:none;

}

.hide {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.required{
  display: inline-block;
  margin-bottom: 4px;
  &:after{
    content: " *";
    display: inline-block;
    margin-left: 2px;
    color: red;
  }
}

.error{
  color: red;
  margin-bottom: 14px;
  display: none;
}
.redtext{
  color: red;
}

.gray-layout{
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  height: 100vh;
  width:0;
  transition: .5s;
  background-color: rgba(0,0,0,0.6);
  &.visible{
    width:100vw;
  }
}
.mt-10{
  margin-top: 10px;
}
.mt-20{
  margin-top: 20px;
}
.mt-30{
  margin-top: 30px;
}
.mt-40{
  margin-top: 40px;
}
.mt-50{
  margin-top: 50px;
}
.mt-60{
  margin-top: 60px;
}
.mt-70{
  margin-top: 70px;
}
.mt-80{
  margin-top: 80px;
}
.mt-90{
  margin-top: 90px;
}
.mt-100{
  margin-top: 100px;
}
.mb-10{
  margin-bottom: 10px;
}
.mb-20{
  margin-bottom: 20px;
}
.mb-30{
  margin-bottom: 30px;
}
.mb-40{
  margin-bottom: 40px;
}
.mb-50{
  margin-bottom: 50px;
}
.mb-60{
  margin-bottom: 60px;
}
.mb-70{
  margin-bottom: 70px;
}
.mb-80{
  margin-bottom: 80px;
}
.mb-90{
  margin-bottom: 90px;
}
.mb-100{
  margin-bottom: 100px;
}
.mt-n40{
  margin-top: -40px;
  &.container{
    margin-top: -40px;
  }
}
.mb-n40{
  margin-bottom: -40px;
  &.container{
    margin-bottom: -40px;
  }
}
@media (max-width: 1023px) {
  h1, .h1like{

  }
  h2, .h2like{

  }
  h3, .h3like{

  }

}


@media (max-width: 767px) {
  .wrapper{
    h1, .h1like{

    }
    h2, .h2like{

    }
    h3, .h3like{

    }
    h4, .h4like{

    }
    .temp-text{

    }
  }
}
@media(max-width: 360px){
  h1, .h1like{

  }
}
/*bitrix fix*/
.row > [id^=bx]{
  display: flex;
  width: 100%;
}
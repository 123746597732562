.search{
  flex:1 1;
  &__input{
    margin-top: 6px;
    margin-bottom: 6px;
    width:100%;
    background-color: $orange-light;
    padding-left: 20px;
    padding-right: 40px;
    color: $white;
    height: 44px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }
  &__submit{
    position: absolute;
    width: 30px;
    height: 30px;
    outline:1px solid $white;
    top: 12px;
    right: 30px;
    border:none;
    background-color: transparent;
    background-image: url(../images/loupe.svg);
    background-size: 80%;
    background-repeat:no-repeat;
    background-position: center center;
    z-index:2;
    font-size: 0;
  }
}
.sticky-catalog-line{
  .search{
    //width:calc(100% - 190px);
    //width:calc(100% - 270px);
    position: relative;
    margin-right: 30px;
    .search__submit{
      right: 15px;
    }
  }
}
@media(max-width: 1365px){
  .sticky-catalog-line{
    .search{
      //width:calc(100% - 150px);
      //width:calc(100% - 240px);
      position: relative;

    }
  }
}
@media(max-width: 1199px){
  .header-cart__mob-title{
    color: $orange;
  }
  .search__input{
    background-color:$orange;
  }
}
@media(max-width: 767px){
  .sticky-catalog-line {
    .search{
      margin-right: 15px;
    }
  }
}
@media(max-width: 480px){
  .search__input{
    padding-left: 15px;
  }
  .search__submit{
    width: 24px;
    height: 24px;
    top: 16px;
  }
  .sticky-catalog-line .search .search__submit{
    right: 10px;
  }
}

.cards{
  //margin-bottom: 30px;
  //transition: .5s;
  overflow: hidden;
  h2{
    font-weight: 400;
  }
  &.active{
    z-index:20;
    position: static;
  }
  &.inactive{
    opacity:0;
    z-index:-20;
    position: absolute;
  }
  .cards__no-items{
    padding-bottom: 50px;
  }
}
//[data-discount="watched"],
//[data-discount="recommend"],
//[data-discount="favorite"]{
//  display: none;
//}
[data-info="empty"]{
  opacity:0;
  z-index:-20;
  position: absolute;
}
.active[data-info="empty"]{
  z-index:20;
  position: static;
  opacity:1;
}
.card{
  background-color: $white;
  height: 320px;
  padding: 15px;
  border-radius:8px;
  border:1px solid transparent;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: $main-color;
  margin-bottom: 30px;
  &__img{
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 10px;
    img{
      max-width:100%;
      max-height:100%;
    }
  }
  &__discount{
    background-color: $red;
    color: $white;
    padding:1px 10px;
    border-radius:6px;
    text-align: center;
    display: inline-block;
    margin-bottom: 4px;
  }
  &:hover{
    border:1px solid $orange;
    .card__title{
      color: $orange;
    }
  }
  &__price{
    font-size: 14px;
    font-weight: 300;
    color: $main-color;
  }
  &__price-member{
    color: $orange;
    font-size: 17px;
    line-height: 17px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  &__price-add{
    font-size: 11px;
    line-height: 11px;
    font-weight: 300;
    color: $main-color;
  }
  &__more{
    color: $white;
    padding: 6px 12px 5px;
    font-size: 14px;
    line-height: 14px;
    border-radius: 6px;
    background-color: $orange;
    border:1px solid transparent;
    margin-bottom: 30px;
    display: inline-block;
    &:hover{
      color: $orange;
      background-color: $white;
      border:1px solid $orange;
    }
  }
}


.card__more-wr{
  display: flex;
  justify-content: flex-end;
}
@media(max-width: 1365px){
  .card__img{
    height: 100px;
  }
  .card__price-member{
    flex-direction: column;
    align-items: flex-start;
  }
}
@media(max-width: 1199px){
  .card__img{
    height: 120px;
  }
  .card__price-member{
    flex-direction: row;
    align-items: flex-end;
  }
}
@media(max-width: 767px){
  .card{
    height: 270px;
  }
}
